.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.b-example-divider {
  width: 100%;
  height: 1.5rem;
  /* background-color: rgba(0, 0, 0, .1); */
  background-color: rgb(240, 240, 240, 1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

body {
  padding-bottom: 50px;
}

footer {
  background-color: white;
}

/* Kart component - Google map */

.google-map {
  width: 100%;
  height: 30vh;
  min-height: 30vh;
  /*margin-top: 30px;
  margin-bottom: 30px;*/
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

.kontaktrad {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.kontaktrad p {
  margin-bottom: 0.2rem;
}

.kontaktContainer .google-map {
  height: 100%;
}

.feilmelding {
  margin-top: 4em;
  margin-bottom: 4em;
}

.feilmelding > h2 {
  margin-bottom: 0.8em;
}

.omoss h1 {
  font-size: 3rem;
  font-weight: 300;
}

.layout p {
  font-weight: 300;
  font-size: 1.2rem;
}

.layout h1 {
  font-weight: 300;
}

.layout h2 {
  font-weight: 300;
}

main .container {
  margin-top: 3em;
  margin-bottom: 3em;
}

.ansatteliste .card-text {
  font-size: 1rem;
}

.kontakt-hjem .container {
  margin-top: 0;
  margin-bottom: 0;
}

.carousel-inner {
  height: 400px;
}

.lightbox-thumbnail {
  max-height: 175px;
  max-width: 175px;
  height: 100%;
  width: 100%;
}

.modal-footer {
  justify-content: center;
}

.lightboxArrowRight {
  position: absolute;
  right: 1.2rem;
  height: 100%;
  color: rgb(205, 205, 205);
}
.lightboxArrowLeft {
  position: absolute;
  top: 16;
  bottom: -16;
  color: rgb(205, 205, 205);
  left: 1.2rem;
  height: 100%;
}

.lightboxArrowLeft:hover {
  color: white;
}

.lightboxArrowRight:hover {
  color: white;
}



.landingPage .utviklingOverskrift {
  margin-top: 100px;
  margin-bottom: 100px;
}

.dropdown-menu {
  border: none;
}

.dropdown-item:hover {
  background-color: rgba(240,240,240,1);
}

html {
  font-size: 0.9rem;
}